import {CommonModule} from '@angular/common';
import {Component, forwardRef, Input} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR,} from '@angular/forms';
import {BaseControlValueAccessor} from '@core/classes/base-control-value-accessor';
import {SelectionControlModel} from '@core/models/navbar.model';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from "primeng/inputnumber";

@Component({
  selector: 'app-radio-button',
  standalone: true,
  imports: [RadioButtonModule, CommonModule, FormsModule, InputNumberModule],
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent extends BaseControlValueAccessor<string> {
  @Input() items: SelectionControlModel[] = [];
  @Input() styleClass!: string;
  @Input() alignment: 'row' | 'col' = 'col';

  onValueChange(value: string) {
    this.onChange(value);
    this.onTouched();
  }
}
